var _this = this;
import 'bootstrap';
import 'jquery';
// Copy to ClipBoard
var copyText = function (text) {
    navigator.clipboard.writeText(text).then(function (res) {
        console.info("Input data copied to clipboard successfully");
    });
};
document.addEventListener('DOMContentLoaded', function (event) {
    document.querySelectorAll(".copy-to-clipboard").forEach(function (item) {
        var value = document.querySelector("#" + item.getAttribute("data-input")).value;
        item.addEventListener("click", copyText.bind(_this, value));
    });
});
